<template>
    <app-modal-new
        name="modal-onboarding-meeting"
        title="PREONBOARDING.MODALS.ONBOARDING_MEETING.TITLE"
        width="lg"
        :adaptive="false"
        :hide-actions="true"
        @on-show="loadScript"
    >
        <template #content>
            <p class="modal-onboarding-meeting__description">
                {{ $t('PREONBOARDING.MODALS.ONBOARDING_MEETING.BODY') }}
            </p>

            <div class="hs_calendar modal-onboarding-meeting__calendar">
                <!-- Start of Meetings Embed Script -->
                <div
                    class="meetings-iframe-container"
                    data-src="https://meetings.hubspot.com/ahmed-eleryan?embed=true"
                ></div>
                <!-- End of Meetings Embed Script -->
            </div>
        </template>
    </app-modal-new>
</template>

<script>
export default {
    methods: {
        loadScript () {
            const scriptTag = document.createElement('script')
            scriptTag.setAttribute('type', 'text/javascript')
            scriptTag.setAttribute('id', 'hs-iframe')
            scriptTag.setAttribute(
                'src',
                'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js'
            )

            document.querySelector('.hs_calendar').appendChild(scriptTag)

            this.$mixpanel.track('Onboarding meeting popup shown')
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@style/sfc.scss';

.modal-onboarding-meeting {
    &__description {
        margin: 0 0 rem(24);
    }

    &__calendar {
        height: rem(670);
    }
}
</style>
