import router from '@/router'
import { authService } from '@/services/auth'
import { userService } from '@/services/api/user'
import { each, keys, find, intersection } from 'lodash-es'
import {
    LOCALES,
    DEFAULT_LOCALE,
    ROUTES,
    ROLES,
    STEP_ASSIGNEE,
    COMPANY_SETTINGS_ROUTES
} from '@/constants'
import { getLocalStorageItem } from '@/services/localStorage'
import { changeLocale } from '@/i18n'
import { routingService } from '@/services/routing'
import { ROUTES_COMPLIANCE } from '@src/modules/compliance/constants'
import { COMPANY_ROLES } from '@src/modules/company-settings/constants'

const user = {}

function getInitialState () {
    return {
        status: {},
        user: null,
        locale: getLocalStorageItem('locale') || DEFAULT_LOCALE,
        isDocumentWizardSeen: false,
        isUserAssignee: false,
        consultant: false,
        impersonated: false,
        myPermissions: []
    }
}

const state = getInitialState()

const actions = {
    async attemptLogin ({ commit }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const loginData = await authService.attemptLogin(data)
            commit('SET_USER_REGISTER_IMPERSONATED', loginData.impersonated)

            return loginData
        } catch (error) {
            commit('LOGIN_FAILURE', error)
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async register ({ commit }, user) {
        commit('SET_APP_LOADING', true, { root: true })

        try {
            await authService.register(user)
            commit('REGISTER_SUCCESS', user)
        } catch (error) {
            commit('REGISTER_FAILURE', error)
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async verifyToken ({ commit }, data) {
        commit('SET_APP_LOADING', true, { root: true })

        try {
            await authService.verifyToken(data)
        } catch (error) {
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async verifyAccount ({ commit }, email) {
        commit('SET_APP_LOADING', true, { root: true })

        try {
            const registerUserType = await authService.verifyAccount(email)
            commit(
                'SET_USER_REGISTER_CONSULTANT',
                registerUserType.data.consultant
            )
        } catch (error) {
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async sendResetPasswordRequest ({ commit, dispatch }, email) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            await authService.sendResetPasswordRequest(email)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })

            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async resetPassword ({ commit }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            await authService.resetPassword(data)
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async changeUserPassword ({ commit }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            await authService.changePassword(data)
        } catch (error) {
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async logout ({ commit }) {
        try {
            authService.logout()

            commit('LOGOUT_SUCCESS')
        } catch (error) {
            throw error
        }
    },

    async reloadUserData ({ commit }) {
        try {
            const user = await authService.getLoggedInUser()

            commit('SET_USER', user)
        } catch (error) {
            throw error
        }
    },

    async doLogin ({ commit }, { token, refreshToken, remember, withLoader }) {
        if (withLoader) {
            commit('SET_APP_LOADING', true, { root: true })
        }
        try {
            authService.setLocalAuthData(token, refreshToken, remember)

            const user = await authService.getLoggedInUser()

            commit('LOGIN_SUCCESS', user)
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getUserMetadata ({ commit }, email) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const response = await authService.getUserMetadata(email)
            commit('SET_USER', response)
            return response
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async setUserMetadata ({ commit }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const response = await authService.setUserMetadata(data)
            commit('SET_USER', response)
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async updateUser ({ commit, dispatch }, data) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const response = await userService.update(data)

            commit('SET_USER', response)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async uploadUserAvatarImage ({ commit, dispatch, getters }, { file }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const { avatar } = await userService.uploadProfileImage(file)

            const user = { ...getters.user, avatar }

            commit('SET_USER', user)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getIsDocumentWizardSeen ({ commit, dispatch }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const { seen } = await userService.getIsDocumentWizardSeen()

            commit('SET_DOCUMENT_WIZARD_SEEN', seen)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async markIsDocumentWizardSeen ({ commit, dispatch }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            await userService.markIsDocumentWizardSeen()

            commit('SET_DOCUMENT_WIZARD_SEEN', true)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async redirectHome ({ getters, rootGetters }) {
        const { user } = getters

        const company = rootGetters['company/company']

        const member = company.members.find(
            member => member.user.id === user.id
        )

        if (
            (member && member.role.name === COMPANY_ROLES.PROJECT_MANAGER) ||
            (member && member.role.name === COMPANY_ROLES.MEMBER)
        ) {
            return router
                .push(
                    routingService.generateStandardBasedRoute(
                        ROUTES_COMPLIANCE.STEPS.name
                    )
                )
                .catch(() => {})
        } else if (member && member.role.name === COMPANY_ROLES.BILLER) {
            return router
                .push(
                    routingService.generateStandardBasedRoute(
                        COMPANY_SETTINGS_ROUTES.SUBSCRIPTION.INDEX.name
                    )
                )
                .catch(() => {})
        } else if (member && member.role.name === COMPANY_ROLES.AUDITOR) {
            return router
                .push(
                    routingService.generateStandardBasedRoute(
                        ROUTES_COMPLIANCE.AUDIT_AND_EVIDENCE.name
                    )
                )
                .catch(() => {})
        }

        return router
            .push(
                routingService.generateStandardBasedRoute(
                    ROUTES.MY_WORK.OVERVIEW.name
                )
            )
            .catch(() => {})
    },

    async checkIfUserIsAssignee ({ commit, rootGetters }) {
        const company = rootGetters['company/company']
        const regulation = rootGetters['company/regulationData']

        if (company && regulation) {
            const isAssignee = await userService.getIsAssignee(
                company.id,
                regulation.id
            )

            commit('SET_IS_USER_ASSIGNEE', isAssignee)
        }
    },

    async getMyPermissions ({ commit, dispatch }, data) {
        try {
            const myPermissions = await userService.getMyPermissions(
                data.companyId,
                data.regulationId
            )

            commit('setMyPermissions', myPermissions)
        } catch (err) {
            dispatch('errors/handleError', err, { root: true })
            throw err
        }
    }
}

const mutations = {
    LOGIN_SUCCESS (state, user) {
        state.status = { loggedIn: true }
        state.user = { ...user }
    },

    LOGIN_FAILURE (state) {
        state.status = {}
        state.user = null
    },

    LOGOUT_SUCCESS (state) {
        state.status = { loggedIn: false }
        state.user = null
    },

    REGISTER_SUCCESS (state) {
        state.status = {}
    },

    REGISTER_FAILURE (state) {
        state.status = {}
    },

    SET_LOCALE (state, { locale }) {
        state.locale = locale
        authService.setLanguageHeader()
        changeLocale(locale)
    },

    SET_USER (state, user) {
        state.user = {
            ...state.user,
            ...user
        }
    },

    SET_USER_REGISTER_CONSULTANT (state, consultant) {
        state.consultant = consultant
    },

    SET_USER_REGISTER_IMPERSONATED (state, impersonated) {
        state.impersonated = impersonated
    },

    RESET_ACCOUNT (state) {
        const s = getInitialState()
        each(keys(state), key => {
            state[key] = s[key]
        })
    },

    SET_DOCUMENT_WIZARD_SEEN (state, isSeen) {
        state.isDocumentWizardSeen = isSeen
    },

    SET_IS_USER_ASSIGNEE (state, isAssignee) {
        state.isUserAssignee = isAssignee
    },

    setMyPermissions (state, myPermissions) {
        state.myPermissions = myPermissions
    },

    resetMyPermissions (state) {
        state.myPermissions = []
    }
}

const getters = {
    user: state => state.user,
    email: state => state.user && state.user.email,
    isLoggedIn: state => state.status?.loggedIn,
    isConsultant: state => state.user && state.user.isConsultant,
    requireTermsOfUse: state =>
        state.user?.isConsultant ? state.user.terms_accepted : true,
    localeForHumans: state => LOCALES[state.locale],
    locale: state => state.locale,
    companyMember: (state, getters, rootState, rootGetters) => {
        const members = rootGetters['company/companyMembers']

        return find(members, member => {
            return member.user && member.user.id === state.user.id
        })
    },
    isDocumentWizardSeen: state => !!state.isDocumentWizardSeen,
    registerTypeConsultant: state => state.consultant
}

export const account = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
